<template>
  <div>
    <div class="summary-top">
      <div class="summary-top_left">我的小结</div>
      <div
        class="summary-top_right flex flex-center"
        @click="pickerVisible = true"
      >
        <div>{{ time }}</div>
        <img
          class="create-way_triangle"
          src="../assets/imgs/triangle.png"
          alt=""
        />
      </div>
    </div>

    <div class="Statistics" @click="router(0)">
      <div class="StatisticsTop">
        <div class="StatisticsTop-left">客户统计</div>
        <div class="flex flex-center">
          <div>查看详情</div>
          <img src="../assets/imgs/arrow.png" alt="" />
        </div>
      </div>
      <div class="StatisticsCenter flex">
        <div class="StatisticsCenter-item flex column flex-center rel">
          <div>新增客户</div>
          <div class="StatisticsCenter-item_add">+{{ more.add_num || 0 }}</div>
          <div class="StatisticsCenter-underline abs"></div>
        </div>
        <div class="StatisticsCenter-item flex column flex-center">
          <div>流失客户</div>
          <div class="StatisticsCenter-item_reduce">
            -{{ more.lost_num || 0 }}
          </div>
        </div>
      </div>
    </div>

    <div class="Statistics" @click="router(1)">
      <div class="StatisticsTop">
        <div class="StatisticsTop-left">客户群统计</div>
        <div class="flex flex-center">
          <div>查看详情</div>
          <img src="../assets/imgs/arrow.png" alt="" />
        </div>
      </div>
      <div class="StatisticsCenter flex">
        <div class="StatisticsCenter-item flex column flex-center rel">
          <div>新增客户群</div>
          <div class="StatisticsCenter-item_add">
            +{{ more.add_group_num || 0 }}
          </div>
          <div class="StatisticsCenter-underline abs"></div>
        </div>
        <div class="StatisticsCenter-item flex column flex-center rel">
          <div>新增客户</div>
          <div class="StatisticsCenter-item_add">
            +{{ more.add_group_contact_num || 0 }}
          </div>
          <div class="StatisticsCenter-underline abs"></div>
        </div>
        <div class="StatisticsCenter-item flex column flex-center">
          <div>流失客户</div>
          <div class="StatisticsCenter-item_reduce">
            -{{ more.lost_group_contact_num || 0 }}
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="Statistics">
      <div class="StatisticsTop">
        <div class="StatisticsTop-left">聊天统计</div>
        <div class="flex flex-center">
          <div>查看详情</div>
          <img src="../assets/imgs/arrow.png" alt="" />
        </div>
      </div>
      <div class="StatisticsCenter flex">
        <div class="StatisticsCenter-item flex column flex-center rel">
          <div>聊天总数</div>
          <div class="StatisticsCenter-item_num">300</div>
          <div class="StatisticsCenter-underline abs"></div>
        </div>
        <div class="StatisticsCenter-item flex column flex-center">
          <div>发送消息数</div>
          <div class="StatisticsCenter-item_num">36</div>
        </div>
      </div>
    </div> -->

    <!-- <div class="Statistics">
      <div class="StatisticsTop">
        <div class="StatisticsTop-left">待办统计</div>
        <div class="flex flex-center">
          <div>查看详情</div>
          <img src="../assets/imgs/arrow.png" alt="" />
        </div>
      </div>
      <div class="StatisticsCenter flex">
        <div class="StatisticsCenter-item flex column flex-center rel">
          <div>待办任务</div>
          <div class="StatisticsCenter-item_num">300</div>
          <div class="StatisticsCenter-underline abs"></div>
        </div>
        <div class="StatisticsCenter-item flex column flex-center">
          <div>已完成任务</div>
          <div class="StatisticsCenter-item_num">36</div>
        </div>
      </div>
    </div> -->

    <VuePicker
      :data="list"
      :showToolbar="true"
      @cancel="pickerVisible = false"
      @confirm="confirm"
      :visible.sync="pickerVisible"
    />
  </div>
</template>

<script>
import VuePicker from "vue-pickers";
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";

export default {
  components: { VuePicker },
  data() {
    return {
      dayIndex: 0,
      list: [],
      pickerVisible: false,
      time: "",
      more: "",
    };
  },
  mounted() {
    this.getDay();
  },
  methods: {
    confirm(res) {
      console.log("res", res);
      this.time = res[0].value;
      this.getList(res[0].value);
    },
    async getDay() {
      let res = await request._getDayList({});
      console.log("30res=", res);
      if (_.toInteger(res.code) === 1) {
        var result = res.data;
        this.time = res.data[0];
        var arr = [];
        this.getList(this.time);
        result.forEach((item) => {
          arr.push({
            label: item,
            value: item,
          });
          this.list = [arr];
        });
      }
    },
    router(type){
      this.$router.push({
        path:"/Statistics",
        query:{
          type
        }
      })
    },
    async getList(day) {
      let res = await request._getDay({
        userid: localStorage.getItem("userid"),
        day,
      });
      console.log("数据=", res);
      if (_.toInteger(res.code) === 1) {
        this.more = res.data.data;
      }
    },
  },
};
</script>

<style scoped>
.summary-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 43px 30px 36px 30px;
}

.summary-top_left {
  font-size: 40px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.summary-top_right {
  font-size: 28px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #101f48;
}

.Statistics {
  width: 690px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px auto;
}

.StatisticsTop {
  width: 630px;
  padding: 30px 0;
  border-bottom: 1px solid #d6e5ee;
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0558f4;
}

.StatisticsTop-left {
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.StatisticsTop img {
  width: 12px;
  height: 20px;
  margin-left: 8px;
}

.StatisticsCenter {
  width: 100%;
}

.StatisticsCenter {
  padding: 28px 0 36px 0;
}

.StatisticsCenter-item {
  width: 50%;
  justify-content: center;
  font-size: 24px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.StatisticsCenter-item_add {
  font-size: 60px;
  font-family: Akrobat-Bold, Akrobat;
  font-weight: bold;
  color: #e1544d;
  margin-top: 7px;
}

.StatisticsCenter-item_reduce {
  font-size: 60px;
  font-family: Akrobat-Bold, Akrobat;
  font-weight: bold;
  color: #58b878;
  margin-top: 7px;
}

.StatisticsCenter-underline {
  width: 1px;
  height: 60px;
  background: #d6e5ee;
  right: 0;
}

.StatisticsCenter-item_num {
  font-size: 60px;
  font-family: Akrobat-Bold, Akrobat;
  font-weight: bold;
  color: #101f48;
  margin-top: 7px;
}

.create-way_triangle {
  width: 12px;
  height: 8px;
  margin-left: 4px;
}
</style>
